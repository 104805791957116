import * as React from "react"
import CoffeeLab from "./coffee-lab"
import Hero from "./hero"
import Testimonial from "./Testominial"
import Taceability from "./traceability"

const Home = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="home_page">
          <Hero pageContext={{ translate }} />
          <div className="content">
            <Taceability pageContext={{ translate }} />
            {/* <Sustain /> */}
            {/* <Problem />         */}
            <CoffeeLab pageContext={{ translate }} />
            <Testimonial pageContext={{ translate }} />
          </div>
        </div>
      }
    </>
  )
}

export default Home
