import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import articalDetails from "../../assets/json/articles/en.json"
import articalDetailsda from "../../assets/json/articles/da.json"
import articalDetailsde from "../../assets/json/articles/de.json"
import articalDetailses from "../../assets/json/articles/es.json"
import articalDetailspt from "../../assets/json/articles/pt.json"
import articalDetailssv from "../../assets/json/articles/sv.json"
import articalDetailsno from "../../assets/json/articles/no.json"

import questionsDetails from "../../assets/json/questions/en.json"
import questionsDetailsda from "../../assets/json/questions/da.json"
import questionsDetailsde from "../../assets/json/questions/de.json"
import questionsDetailses from "../../assets/json/questions/es.json"
import questionsDetailspt from "../../assets/json/questions/pt.json"
import questionsDetailssv from "../../assets/json/questions/sv.json"
import questionsDetailsno from "../../assets/json/questions/no.json"

import recipesDetails from "../../assets/json/recipes/en.json"
import recipesDetailsda from "../../assets/json/recipes/da.json"
import recipesDetailsde from "../../assets/json/recipes/de.json"
import recipesDetailses from "../../assets/json/recipes/es.json"
import recipesDetailspt from "../../assets/json/recipes/pt.json"
import recipesDetailssv from "../../assets/json/recipes/sv.json"
import recipesDetailsno from "../../assets/json/recipes/no.json"

import ArticalCard from "./ArticalCard"
import RecipesCard from "./RecipesCard"
import UserHeader from "./UserHeader"

const CoffeeLab = ({ pageContext: { translate } }) => {
  const [questions, setQuestions] = useState(questionsDetails.questions)
  const [articles, setArticles] = useState(articalDetails)
  const [recipes, setRecipes] = useState(recipesDetails)
  const [tabs, setTabs] = useState("posts")

  
  useEffect(() => {

    // conditions for dynamically loading article json with specified language
    if(translate.currentLang === "en") {
      setArticles(articalDetails)
    }
    if(translate.currentLang === "da") {
      setArticles(articalDetailsda || articalDetails)
    }

    if(translate.currentLang === "de") {
      setArticles(articalDetailsde || articalDetails)
    }

    if(translate.currentLang === "es") {
      setArticles(articalDetailses || articalDetails)
    }
    
    if(translate.currentLang === "pt") {
      setArticles(articalDetailspt || articalDetails)
    }

    if(translate.currentLang === "sv") {
      setArticles(articalDetailssv || articalDetails)
    }

    if(translate.currentLang === "no") {
      setArticles(articalDetailsno || articalDetails)
    }

    // conditions for dynamically loading recipe json with specified language
        if(translate.currentLang === "en"){
          setRecipes(recipesDetails)
        }

        if(translate.currentLang === "da") {
          setRecipes(recipesDetailsda || recipesDetails)
        }

        if(translate.currentLang === "de") {
          setRecipes(recipesDetailsde || recipesDetails)
        }

        if(translate.currentLang === "es") {
          setRecipes(recipesDetailses || recipesDetails)
        }

        if(translate.currentLang === "pt") {
          setRecipes(recipesDetailspt || recipesDetails)
        }

        if(translate.currentLang === "sv") {
          setRecipes(recipesDetailssv || recipesDetails)
        }
        if(translate.currentLang === "no") {
          setRecipes(recipesDetailsno || recipesDetails)
        }

    // conditions for dynamically loading question json with specified language

         if(translate.currentLang === "en") {
          setQuestions(questionsDetails?.questions)
        }

        if(translate.currentLang === "da") {
          setQuestions(questionsDetailsda?.questions || questionsDetails?.questions)
        }
        
        if(translate.currentLang === "de") {
          setQuestions(questionsDetailsde?.questions || questionsDetails?.questions)
        }

        if(translate.currentLang === "es"){
          setQuestions(questionsDetailses?.questions || questionsDetails?.questions)
        }

        if(translate.currentLang === "pt"){
          setQuestions(questionsDetailspt?.questions || questionsDetails?.questions)
        }

        if(translate.currentLang === "sv"){
          setQuestions(questionsDetailssv?.questions || questionsDetails?.questions)
        }
        if(translate.currentLang === "no"){
          setQuestions(questionsDetailsno?.questions || questionsDetails?.questions)
        }
  }, [])

  return (
    <>
      {
        <div className="coffee-lab">
          <div className="content py-5">
            <div className="the-coffee-lab mx-3 pb-lg-4">
              <div className="row justify-content-between mx-0 mx-md-auto pt-lg-4 pb-lg-5">
                <h1 className="fnt-20 fnt-md-22 fnt-lg-30 mb-4 fw-bold txt-clr223">
                  {translate.t?.an_inclusive_coffee}
                </h1>
                <div className="d-flex align-items-center py-3 py-md-0">
                  {/* <Link className="watch-video mx-3 px-3 txt-clr5c fw-bold cursor-pointer"><i className="fa fa-duotone fa-play"></i> Watch a video</Link> */}
                  <Link
                    to={`${process.env.GATSBY_SITE_URL}/coffee-lab/${!!translate.currentLang ? `${translate.currentLang}/` : ''}qa-forum`}
                    className="btndefault greenbtn cursor-pointer"
                  >
                    {translate.t?.explore_the_forum}
                  </Link>
                </div>
              </div>
              <div className="tabview mb-4">
                <div className="d-flex align-items-center tabview-nav">
                <button
                    className={`tab ${tabs === "posts" ? "active" : ""}`}
                    onClick={() => setTabs("posts")}
                  >
                    <span>{translate.t?.posts}</span>
                  </button>
                  <button
                    className={`tab ${tabs === "qaForum" ? "active" : ""}`}
                    onClick={() => setTabs("qaForum")}
                  >
                    <span>{translate.t?.q_and_a_forum}</span>
                  </button>
                 
                  <button
                    className={`tab ${tabs === "brewing" ? "active" : ""}`}
                    onClick={() => setTabs("brewing")}
                  >
                    <span>{translate.t?.brewing_guides}</span>
                  </button>
                </div>
              </div>
              {tabs === "qaForum" ? (
                <div className="row mx-0">
                  {questions?.map(item => (
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                      <div className="d-flex">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center w-100">
                            <UserHeader imageUrl={item.profile_image_url} />
                            <div className="ml-25 flex-1">
                              <div className="d-flex align-items-center mr-3 text-nowrap">
                                <div className="fw-sb mr-md-3 mr-1 ellipsis">
                                  <span>{item.question_user}</span>
                                </div>
                              </div>
                              <div className="tc-m mt-1 d-flex text-nowrap justify-content-md-between">
                                <div>
                                  <span className="mr-1">Coffee experts</span>
                                  <span className="ellipsis ">
                                    @
                                    {item.company_name
                                      ? item.company_name
                                      : "Era of We"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex pt-1 mt-2">
                        <Link
                          className="fnt-16 question-hover"
                          to={`${process.env.GATSBY_SITE_URL}/coffee-lab/${item.lang_code}/qa-forum/${item.slug}`}
                          dangerouslySetInnerHTML={{
                            __html: item.question,
                          }}
                        ></Link>
                      </div>
                      <div className="d-flex mt-4">
                        <div className="row mx-0">
                          <div className="mr-md-25 mb-4 ">
                            <Link
                              className="category-btn fnt-12 mb-2"
                              to={`${process.env.GATSBY_SITE_URL}/coffee-lab/${translate.currentLang}/coffee-farm/qa-forum`}
                            >
                              Coffee farm
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : tabs === "posts" ? (
                <div className="row mx-0">
                  {articles?.slice(0, 3).map(article => {
                    return (
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                      <ArticalCard article={article} />
                    </div>)
                  }
                 )}
                </div>
              ) : (
                <div className="row mx-0">
                  {recipes?.map(recipe => {
                    return (
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                      <RecipesCard recipe={recipe} />
                    </div>
                  )
                  }
                )}
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default CoffeeLab
