import * as React from "react"
import Link from "../link"
import Fazenda from "../../assets/images/Fazenda_California.webp"
import Luiz from "../../assets/images/Ama_Luiz.webp"
import Fredrik from "../../assets/images/Fredrik_Lo_fbergs.webp"

const Testominial = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="testominial pb-lg-5">
          <div className="information pb-lg-5">
            <div className="inner-content text-center pt-4 mx-auto w-75">
              {/* <h2>{translate.t?.testimonials}</h2> */}
              <h3>{translate.t?.trusted_by_farmers}</h3>
              <p>{translate.t?.a_platform_built}</p>
              <div className="d-flex justify-content-center">
                <Link
                  className="mx-2"
                  to={`${process.env.GATSBY_SSO_WEB}/sign-up`}
                >
                  <button className="btndefault greenbtn">
                    {translate.t?.book_a_demo}
                  </button>
                </Link>
                <Link
                  className="mx-2"
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }community/`}
                >
                  <button className="btndefault whitebtn">
                    {translate.t?.view_our_community}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="content py-4 pb-md-0 pt-md-5">
            <div className="user-box">
              <div className="row">
                <div className="col-12 col-md-4 py-4 py-md-2 position-relative">
                  <div className="p-4 bg-white user h-100">
                    <div className="pb-45 d-flex">
                      <img src={Fazenda} alt="Fazenda" />
                      <div className="user-details">
                        <h4 className="mb-1">Flavia & Luiz Rodrigues</h4>
                        <p>Fazenda California</p>
                      </div>
                    </div>
                    <p className="mb-0">{translate.t?.public_quote_1}</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 py-4 py-md-2 position-relative">
                  <div className="p-4 bg-white user h-100">
                    <div className="pb-45 d-flex">
                      <img src={Luiz} alt="Luiz" />
                      <div className="user-details">
                        <h4 className="mb-1">Luiz Paulo</h4>
                        <p>AMA Coffee Producer</p>
                      </div>
                    </div>
                    <p className="mb-0">{translate.t?.tbh_eraofwe_newera}</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 py-4 py-md-2 position-relative">
                  <div className="p-4 bg-white user h-100">
                    <div className="pb-45 d-flex">
                      <img src={Fredrik} alt="Fredrik" />
                      <div className="user-details">
                        <h4 className="mb-1">Fredrik Nilsson</h4>
                        <p>CCO Sweden - Löfbergs </p>
                      </div>
                    </div>
                    <p className="mb-0">{translate.t?.public_quote_3}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Testominial
