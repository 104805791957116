import React from "react"
import UserHeader from "./UserHeader"
import { Link } from "gatsby"

const RecipesCard = props => {
  return (
    <Link
      className="text-non-underline"
      to={`${process.env.GATSBY_SITE_URL}/coffee-lab/${props.recipe.lang_code}/coffee-recipes/${props.recipe.slug}`}
    >
      <div className="coffee-item">
        <div className="position-relative">
          <div className="d-flex justify-content-center card-hght">
            <img
              src={props.recipe.cover_image_thumb_url}
              alt="cover_image"
              className="ng-lazyloaded"
            />
          </div>
          <span className="expertise"> Easy </span>
        </div>
        <div className="m-3 m-md-4">
          <div className="d-flex justify-content-between mb-4">
            <div className="recipe-name flex-1 fnt-md-16">
              <span className="mb-1 fnt-16 d-flex recipe-title text-break fw-sb txt-clr223">
                {props.recipe?.name?.length < 60
                  ? props.recipe?.name
                  : props.recipe?.name.slice(0, 60).concat("...")}
              </span>
              <span className="equipment mb-45 mb-md-4 mt-2 txt-clr223">
                {props.recipe.equipment_name}
              </span>
            </div>
            <div className="btn-icon save ml-1"></div>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center w-100">
                <UserHeader imageUrl={props.recipe.profile_image_thumb_url} />
                <div className="ml-25 flex-1">
                  <div className="d-flex align-items-center mr-3 text-nowrap">
                    <div className="txt-clr223 fw-sb mr-md-3 mr-1 ellipsis">
                      <span> {props.recipe.posted_user} </span>
                    </div>
                  </div>
                  <div className="tc-m mt-1 d-flex text-nowrap">
                    <div>
                      <span className="mr-1 ng-star-inserted">
                        Coffee experts
                      </span>
                      <span className="ellipsis ng-star-inserted">
                        @
                        {props.recipe.organisation_name
                          ? props.recipe.organisation_name
                          : "Era of We"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default RecipesCard
