import React from "react"
import avatar from "../../assets/images/default-avatar.png"

const UserHeader = props => {
  return (
    <>
      <div className="avatar">
        <img src={props.imageUrl || avatar} alt="avatar" className="user" />
      </div>
    </>
  )
}

export default UserHeader
