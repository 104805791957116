import React from "react"
import UserHeader from "./UserHeader"
import { Link } from "gatsby"

const ArticalCard = props => {
  return (
    <Link
      className="text-non-underline"
      to={`${process.env.GATSBY_SITE_URL}/coffee-lab/${props.article.language}/articles/${props.article.slug}`}
    >
      <div className="py-3 py-md-4 article-item">
        <div className="d-flex justify-content-between px-4">
          <div className="flex-1 overflow-hidden">
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center w-100">
                  <UserHeader
                    imageUrl={props.article.profile_image_thumb_url}
                  />
                  <div className="flex-1">
                    <div className="d-flex align-items-center mr-3 text-nowrap">
                      <div className="fw-sb mr-md-3 mr-1 txt-clr223 ellipsis">
                        <span> {props.article.user_name} </span>
                      </div>
                    </div>
                    <div className="tc-m mt-1 d-flex text-nowrap">
                      <div>
                        <span className="mr-1 ">Coffee experts</span>
                        <span className="ellipsis ">
                          @
                          {props.article.organisation_name
                            ? props.article.organisation_name
                            : "Era of We"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="btn-icon save"></div>
        </div>
        <div className="d-flex justify-content-center card-hght my-3">
          <img
            src={props.article.cover_image_thumb_url}
            alt="give a title inhhere ok?Daniel?"
            className="ng-lazyloaded"
          />
        </div>
        <div className="mx-4">
          <div className="d-flex justify-content-between">
            <div className="btn-icon thumbsup"></div>
            <span className="tc-m mt-2">
              {" "}
              {new Date(props.article.created_at).toDateString()}{" "}
            </span>
          </div>
          <h5 className="fw-sb lh-157 fnt-14 txt-clr223 cursor-pointerer article-title-text mt-25 mt-md-3 mb-0">
            {props.article.title}
          </h5>
          <div className="lh-143 mt-2 mt-md-3">
            <span className="article-title-text">{props.article.subtitle}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArticalCard
