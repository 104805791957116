import React, { useState, useEffect } from "react"
import Link from "../link"
import trace1 from "../../assets/images/trance01.webp"
import trace2 from "../../assets/images/trance02.webp"
import trace3 from "../../assets/images/trance03.webp"
import sustain1 from "../../assets/images/sustain1.webp"
import sustain2 from "../../assets/images/sustain2.webp"
import estates from "../../assets/json/estates/estates.json"
import Empty from "../../assets/images/empty.svg"

const Taceability = ({ pageContext: { translate } }) => {
  const [traceTabs, setTraceTabs] = useState("traceability")
  const [homepageVisibility, setHomepageVisibility] = useState([])

  return (
    <>
      {
        <div className="trace pt-5">
          <div className="pt-lg-4">
            <div className="trace-tabview d-flex justify-content-center">
              <div className="d-flex align-items-center tabview-nav">
                <button
                  className={`tab ${
                    traceTabs === "traceability" ? "active" : ""
                  }`}
                  onClick={() => setTraceTabs("traceability")}
                >
                  <span className="tab-title fw-700">  {translate.t?.traceability}</span>
                </button>
                <button
                  className={`tab ${
                    traceTabs === "sustainability" ? "active" : ""
                  }`}
                  onClick={() => setTraceTabs("sustainability")}
                >
                  <span className="tab-title fw-700">{translate.t?.sustainability}</span>
                </button>
              </div>
            </div>
          </div>

          {traceTabs === "traceability" ? (
            <div className="content pt-3 pt-md-5">
              <div className="information">
                <h3>{translate.t?.trace_the_coffee}</h3>
                <p>{translate.t?.honor_those_that}</p>
                <div className="d-none d-md-block mb-3">
                  <div className="row mx-0">
                    {Array.isArray(estates) &&
                      estates.map(item => (
                        <div className="col-3 d-flex justify-content-center align-items-center py-2">
                          <a
                            href={`${process.env.GATSBY_SITE_URL}/estate/${item.slug}`}
                            target="_blank"
                          >
                            <img
                              className={`img-fluid ${
                                item.company_image_thumbnail_url
                                  ? ""
                                  : "p-3 bg-eb"
                              }`}
                              src={item.company_image_thumbnail_url || Empty}
                              alt={item.company_name}
                            />
                          </a>{" "}
                        </div>
                      ))}
                  </div>
                </div>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }traceability/`}
                >
                  <button className="btndefault greenbtn HomePageTraceBtn">
                    {translate.t?.our_traceability_steps}
                  </button>
                </Link>
              </div>
              <div className="position-relative d-none d-md-block trace-img">
                <div className="trace-image1">
                  <img className="img-fluid" src={trace1} alt="trace" />
                </div>
                <div className="trace-image2">
                  <img className="img-fluid" src={trace2} alt="trace" />
                </div>
                <div className="trace-image3">
                  <img className="img-fluid" src={trace3} alt="trace" />
                </div>
              </div>
              <div className="mobile-img d-md-none w-100">
                <div className="dual">
                  <div>
                    <img src={trace1} alt="Traceability" />
                  </div>
                  <div>
                    <img src={trace2} alt="Traceability" />
                  </div>
                  <div>
                    <img src={trace3} alt="Traceability_2" />
                  </div>
                </div>
                <div className="row mx-0 mb-3">
                  {homepageVisibility.result &&
                    homepageVisibility?.result.map(item => (
                      <div className="col-3 d-flex justify-content-center align-items-center py-2">
                        <img
                          className="img-fluid"
                          src={item.company_image_url}
                          alt={item.company_name}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="content pt-3 pt-md-5">
              <div className="information">
                <h3>{translate.t?.tackling_sustainability}</h3>
                <p>{translate.t?.we_advocate_sustainability}</p>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }sustainability/`}
                >
                  <button className="btndefault greenbtn HomePageTraceBtn">
                    {translate.t?.our_sustainability_steps}
                  </button>
                </Link>
              </div>
              <div className="mobile-img">
                <div className="dual d-md-flex">
                  <div className="mr-md-3 pr-md-3">
                    <img
                      src={sustain1}
                      alt="Sustainability"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <img
                      src={sustain2}
                      alt="Sustainability"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}

export default Taceability
